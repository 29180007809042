import { JobApplicationStatus } from './job-application-status.model';
import { File } from './file.model';
import { Job } from './job.model';

export class JobApplication {
    constructor(
        public createdDate: string = "",
        public createdBy: string = "",
        public id: string = "",
        public firstname: string = "",
        public lastname: string = "",
        public email: string = "",
        public phoneNumber: string = "",
        public countryDialCode: string = "",
        public websiteURL: string = "",
        public whatsappNotifiable: boolean = false,
        public emailNotifiable: boolean = false,
        public closed: boolean = false,
        public job: Job = new Job(),
        public file: File = new File(),
        public jobApplicationStatuses: JobApplicationStatus[] = [],
        public fullname: string = ""
    ){}
}