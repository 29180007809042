import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {
  CloseCircleOutlined,
  MailOutlined,
  SaveOutlined,
  UploadOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Button, CheckBox, Header, Input } from '../../../../../core/components';
import Select from 'react-select';
import { Upload } from 'antd';
import Swal from 'sweetalert2';
import { JobApplication } from '../../../../../core/models/jobs/job-application.model';
import { ICheckboxOption } from '../../../../../core/interfaces';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UtilService } from '../../../../../core/services/util.service';
import { JobService } from '../../services/job.service';

interface Props {
  isOpen: boolean;
  job: any;
  onClose: () => void;
}

interface Option {
  value: string;
  label: React.ReactNode;
  dial_code: string;
}

const JobApplicationModal: React.FC<Props> = ({ isOpen, job, onClose }) => {
  const countryOptions: Option[] = require('../../../../../assets/countries.json').map(
    (country: any) => ({
      value: country.code,
      dial_code: country.dial_code,
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={country.flag}
            alt={country.name}
            style={{ marginRight: '10px', width: '20px', height: '15px' }}
          />
          ({country.dial_code})
        </div>
      ),
    })
  );
  const defaultCountry: Option = countryOptions.find((option: any) => option.value === 'CR')!;

  const [selectedCountry, setSelectedCountry] = useState<Option | null>(defaultCountry);

  const [application, setApplication] = useState<JobApplication>(new JobApplication());

  const [selecteds, setSelecteds] = useState<ICheckboxOption[]>([]);

  const [file, setFile] = useState<File | null>(null);

  const emailNotifiable = {
    name: 'ckbEmail',
    label: 'Email',
    value: 'ckbEmail',
    icon: <MailOutlined />,
    disabled: true,
  };
  const whatsappNotifiable = {
    name: 'ckbWhatsApp',
    label: 'WhatsApp',
    value: 'ckbWhatsApp',
    icon: <WhatsAppOutlined />,
    disabled: false,
  };

  const options = [emailNotifiable, whatsappNotifiable];

  useEffect(() => {
    setApplication(new JobApplication());
    setFile(null);
    setSelecteds([emailNotifiable]);

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleCountryChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      setSelectedCountry(selectedOption);
      setApplication((prevApplication) => {
        const phoneNumberWithoutCode = prevApplication.phoneNumber.replace(
          new RegExp(`^\\+${selectedCountry?.dial_code || ''}`),
          ''
        );
        return {
          ...prevApplication,
          phoneNumber: `+${selectedOption.dial_code}${phoneNumberWithoutCode}`,
        };
      });
    }
  };

  const OnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber' && selectedCountry) {
      const phoneNumberWithDialCode = `+${
        selectedCountry.dial_code || defaultCountry.dial_code
      }${value}`;
      setApplication((prevApplication) => ({
        ...prevApplication,
        phoneNumber: phoneNumberWithDialCode,
      }));
    } else {
      setApplication((prevApplication) => ({
        ...prevApplication,
        [name]: value,
      }));
    }
  };

  const OnChangeSelected = (e: CheckboxChangeEvent, option: ICheckboxOption) => {
    setSelecteds((prevSelecteds) => {
      if (e.target.checked) {
        return [...prevSelecteds, option];
      }
      return prevSelecteds.filter((item) => item.value !== option.value);
    });
  };

  const handleFileChange = (info: any) => {
    if (info.file && info.file.status && info.file.status === 'removed') {
      setFile(null);
    } else if (info.file) {
      setFile(info.file);
    }
  };

  const Validate = async (application: JobApplication, callback: () => Promise<void>) => {
    const errors: string[] = [];

    if (application.firstname.length === 0) {
      errors.push('You must enter the first name.');
    }
    if (application.lastname.length === 0) {
      errors.push('You must enter the last name.');
    }
    if (application.phoneNumber.length === 0) {
      errors.push('You must enter the phone number.');
    }
    if (!selectedCountry || !selectedCountry.value) {
      errors.push('You must select a country.');
    }
    if (application.email.length === 0) {
      errors.push('You must enter the email.');
    }
    if (application.websiteURL.length === 0) {
      errors.push('You must enter the profile url.');
    }

    if (!file) {
      errors.push('You must select the file.');
    }

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));

      return;
    }

    await callback();
  };

  const Save = async () => {
    try {
      let response = await JobService.EasyApply(
        job.id,
        {
          ...application,
          whatsappNotifiable: selecteds.some((x) => x.value === 'ckbWhatsApp'),
          emailNotifiable: selecteds.some((x) => x.value === 'ckbEmail'),
        },

        file!
      );
      if (response) {
        console.log('response', response);
        UtilService.Alert('Success!', 'success', 'The application has been sended.');

        onClose();
      }
    } catch (error: any) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'info',
        title: error.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className={'h-fit p-4 w-full max-w-4xl'}
      overlayClassName='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] scrollbar-hide'
      ariaHideApp={false}>
      <div className='flex flex-col max-h-screen overflow-y-auto scrollbar-hide h-full p-8'>
        <div className='bg-white p-8 rounded-lg'>
          <div>
            <Header
              title='Apply to Job'
              buttons={[
                {
                  icon: {
                    value: <CloseCircleOutlined />,
                    size: 'text-lg',
                  },
                  text: {
                    size: 'md',
                    value: 'Close',
                    weight: 500,
                  },
                  color: 'red',
                  tone: 500,
                  onClick: onClose,
                },
              ]}
            />
          </div>

          <div className='mt-2'>
            <div className='grid grid-cols-1 lg:grid-cols-2 mb-4 gap-x-5 gap-y-5'>
              <Input
                title='Category'
                placeholder='Select an option'
                name='category'
                value={job.category}
                disabled={true}
                type='text'
              />

              <Input
                title='Sub Category'
                placeholder='Select an option'
                name='subCategory'
                value={job.subCategory}
                disabled={true}
                type='text'
              />
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
              <Input
                title='Job Name'
                placeholder='Enter the Job Name...'
                type='string'
                id='name'
                name='name'
                value={job.name}
                inputClass=''
                OnChange={() => {}}
                disabled={true}
              />

              <Input
                title='Location'
                placeholder='Select an option'
                name='location'
                value={job.location.replace('-', ' ')}
                disabled={true}
                type='text'
              />
            </div>
          </div>

          <div>
            <div className='border-dashed border border-gray-400 mb-4 mt-4'></div>

            <div>
              <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
                <Input
                  title='First Name'
                  type='string'
                  name='firstname'
                  placeholder='Enter the first name'
                  value={application.firstname}
                  OnChange={OnChangeInput}
                />

                <Input
                  title='Last Name'
                  type='string'
                  name='lastname'
                  placeholder='Enter the last name'
                  value={application.lastname}
                  OnChange={OnChangeInput}
                />
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 mb-6 gap-x-5 gap-y-0'>
                <div>
                  <div className='flex'>
                    <span className='text-left block mb-2 text-sm font-medium text-gray-600'>
                      Phone Number
                    </span>
                  </div>
                  <div className='grid grid-cols-12 mb-5 gap-x-2 gap-y-2'>
                    <div className='col-span-12 xs:col-span-4 sm:col-span-4 lg:col-span-5'>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '0.5rem',
                            borderColor: '#d1d5db',
                            boxShadow: 'none',
                            '&:hover': { borderColor: '#9ca3af' },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '0.5rem',
                          }),
                        }}
                        options={countryOptions}
                        onChange={handleCountryChange}
                        placeholder='Select a country'
                        value={selectedCountry}
                      />
                    </div>

                    <div className='col-span-12 xs:col-span-8 sm:col-span-8 lg:col-span-7'>
                      <Input
                        placeholder='Enter the phone number'
                        type='string'
                        name='phoneNumber'
                        value={application.phoneNumber.replace(
                          selectedCountry?.dial_code || '',
                          ''
                        )}
                        OnChange={OnChangeInput}
                        mask='999999999999999'
                        maskChar=''
                      />
                    </div>
                  </div>
                </div>

                <Input
                  title='Email'
                  type='string'
                  name='email'
                  placeholder='Enter the email'
                  value={application.email}
                  OnChange={OnChangeInput}
                />
              </div>

              <div className='grid grid-cols-1 mb-5 gap-x-5 gap-y-0 mt-5 lg:mt-0'>
                <Input
                  title='Professional Profile Url'
                  type='string'
                  name='websiteURL'
                  placeholder='Enter your professional profile url'
                  value={application.websiteURL}
                  OnChange={OnChangeInput}
                />
              </div>
            </div>

            <div className='grid grid-cols-1 mb-5 gap-x-5 gap-y-5'>
              <div>
                <span className='text-left block mb-2 text-sm font-medium text-gray-600'>
                  Attach CV
                </span>

                <Upload
                  beforeUpload={() => false}
                  showUploadList={true}
                  onChange={handleFileChange}
                  maxCount={1}>
                  <Button
                    icon={{ value: <UploadOutlined />, size: 'text-lg' }}
                    text={{
                      value: 'Select File',
                      weight: 400,
                      size: 'text-md',
                    }}
                    color='blue'
                    tone={500}
                  />
                </Upload>
              </div>
            </div>
          </div>

          <div>
            <div className='border-dashed border border-gray-400 mb-4 mt-4'></div>

            <div className='grid grid-cols-1 mb-5 gap-x-5 gap-y-3'>
              <div className='flex items-center justify-start mt-0'>
                <CheckBox
                  title='Be notified On'
                  options={options}
                  selected={selecteds}
                  onChange={OnChangeSelected}
                  layout='horizontal'
                />
              </div>
            </div>
          </div>

          <div className='flex items-center justify-end mt-auto'>
            <Button
              icon={{ value: <SaveOutlined />, size: 'text-lg' }}
              text={{ value: 'Apply', weight: 400, size: 'text-md' }}
              color='blue'
              tone={500}
              onClick={() => {
                Validate(application, Save);
              }}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default JobApplicationModal;
